<template>
  <div class="get_coupon_wrap" v-if="!coupons_loading">
    <div class="bg_top"></div>
    <div class="bg_bottom"></div>
    <div class="bg_right"></div>
    <div class="bg_center">
      <div class="bg_left"></div>
      <div class="title"></div>
      <el-row>
          <el-col :span="10" :offset="7" class="text-left">
            <Coupons :coupons="issued_coupons.coupons"
                           :user_limit_visible="true"
                           :btn_issue="true"
                           :btn_detail="false"
                           @issue-coupons="issueCoupons"></Coupons>

            <div class="buttons" v-if="issued_coupons.coupons.user_issued > 0">
              <el-button type="warning" @click="showMyCoupons">查看我的优惠券</el-button>
            </div>

          </el-col>
      </el-row>
    </div>
  </div>
  <div v-else v-loading="coupons_loading"
       class="get_coupon_wrap_no_bg"></div>
</template>

<script>
  import Coupons from "components/settlement/Coupons"
  import {fmtCouponsInfo} from 'api/settlement/coupons/admin'
  import {issueLink, listCouonsUser, getCouponsByStrategyCode} from 'api/settlement/coupons/user'
  import { GetUserState, LoginUser, LogOutUser } from 'api/user/auth/login'
  import { db_time_to_local_fmt } from 'utils/timeutils'
  import { is_mobile } from 'utils/common'

  export default {
    components: {
      Coupons,
    },
    created() {

      if (this.$route.params.strategy_code != undefined && this.$route.params.strategy_code != '') {
        this.strategy_code = this.$route.params.strategy_code;

        if(is_mobile()){
            window.location.href = `/mobile/coupons/issue/${this.strategy_code}`;
            return;
        }

        this.loadData();

      } else {

          this.result_mode = 'error';
          this.result_msg = '非法访问,参数丢失';

      }
    },
    data() {

     return {
       coupons_loading: true,
       issue_loading: false,
       result_mode: null,
       result_msg: null,
       show_my_coupons: false,
       issued_coupons: {
           coupons:{
               user_issued: 0
           }
       },
       issued_remain_count_text: '',
       is_user_limit: true,
       user_remain_count: 0,
       strategy_code: null,
     }
    },
    methods: {

      async loadData(){

          const that = this;

          await getCouponsByStrategyCode(this.strategy_code).then(result=>{

              if (result.count > 0){

                  that.issued_coupons.coupons = fmtCouponsInfo(result.data);

              } else {
                  this.goPath('/404')
              }

              that.coupons_loading = false;

          }).catch(err=>{

            this.result_mode = 'error';
            this.result_msg = err;

          });
      },

      async issueCoupons(){

        const that = this;

        this.issue_loading = true;

        await issueLink(this.strategy_code).then(result => {

            that.issue_loading = false;

            if (result.succeed){

                this.result_mode = 'succeed';

                if(result.instance.coupons){
                    result.instance.coupons = fmtCouponsInfo(result.instance.coupons);
                    that.issued_coupons.coupons  = result.instance.coupons;
                }

                result.instance.expired_at = db_time_to_local_fmt(result.instance.time_end);

                this.issued_coupons = result.instance;
                this.user_remain_count = result.user_remain_count;
                this.is_user_limit = result.is_user_limit;
                this.show_my_coupons = true;

                this.$notify({
                  title: '领取成功',
                  message: '优惠券已放入卡包~',
                  type: 'success'
                });

                if (result.is_user_limit && result.user_remain_count) {
                    this.issued_remain_count_text = `（还可领取${result.user_remain_count}次）`;
                } else {
                    this.issued_remain_count_text = '';
                }

            } else {

                switch(result.error_code){
                  case -9999: { // not login

                    this.result_mode = 'not_login';
                    this.showLoginModal();

                  } break;
                  default: {

                    this.result_mode = 'error';
                    this.result_msg = result.error_msg;
//                    this.show_my_coupons = true;

                    this.$notify({
                      title: '提示',
                      message: result.error_msg,
                      type: 'warning'
                    });

                  } break;
                }
            }


        }).catch(err => {

          that.issue_loading = false;

          this.result_mode = 'error';
          this.result_msg = err;

        });
        console.log(this.issued_coupons.coupons);

      },
      goPath(path) {
        this.$router.push(path);
      },
      showMyCoupons(){
          this.goPath('/center/card/coupons')
      },
      showLoginModal(){

       this.$emit('update:loginVisible', true);

      }
    }
  }
</script>

<style lang="scss">
  .notification_wrap {
    .el-notification__content {
      color:#999;
    }
  }
</style>
<style lang="scss" scoped>
  .get_coupon_wrap {
    background-image: linear-gradient(to right, #a164ff , #6263ff);
    margin:-20px;
    padding-top:60px;
    padding-bottom:90px;
    position:relative;
    .bg_top {
      height:224px;
      background:url('~@/assets/img/bg/coupon_bg1.png') no-repeat;
      background-size:100% 100%;
      position:absolute;
      top:0;
      left:0;
      right:0;
    }
    .bg_bottom {
      height:164px;
      background:url('~@/assets/img/bg/coupon_bg2.png') no-repeat;
      background-size:100% 100%;
      position:absolute;
      bottom:0;
      left:0;
      right:0;
    }
    .bg_right {
      width:150px;
      height:301px;
      background:url('~@/assets/img/bg/coupon_bg3.png') no-repeat;
      position:absolute;
      bottom:0;
      right:0;
    }
    .bg_center {
      width:1281px;
      height:737px;
      background:url('~@/assets/img/bg/coupon_kuang.png') no-repeat;
      background-size:100% 100%;
      z-index:1;
      margin:0 auto;
      position:relative;
      .bg_left {
        position:absolute;
        width:362px;
        height:387px;
        background:url('~@/assets/img/bg/coupon_bg4.png') no-repeat;
        bottom:-20px;
        left:-45px;
      }
      .title {
        width:481px;
        height:300px;
        background:url('~@/assets/img/bg/coupon_title.png') no-repeat bottom center;
        margin:0 auto 65px;
        padding-top:180px;
      }
      .buttons {
        padding-top:30px;
        text-align:center;
      }
    }
  }
  .get_coupon_wrap_no_bg {
    margin:-20px;
    padding-top:60px;
    padding-bottom:90px;
    position:relative;
  }
  @media screen and (max-width: 1366px) {
    .get_coupon_wrap {
      .bg_center {
        width:1200px;
      }
    }
  }
</style>
